<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <b-card-body>
          <h2>My Apps</h2>
          <hr />
          <b-row class="justify-content-center">
            <b-col
              class="col-lg-4 col-md-6 col-sm-12"
              :key="account.name"
              v-for="account in accounts"
            >
              <div class="border rounded mb-2 pb-1">
                <h4 class="text-center mt-2">
                  {{ account.account_name }}
                </h4>
                <hr />
                <div class="d-flex justify-content-between px-1 pb-1 pt-0">
                  <span>Apps: </span>
                  <b>{{ account.apps_count }}</b>
                </div>
                <div class="d-flex justify-content-between px-1 pb-1 pt-0">
                  <span>Total Installs: </span>
                  <b>{{ account.apps_installs }}</b>
                </div>
                <div class="d-flex justify-content-between px-1 pb-1 pt-0">
                  <span>Total Reviews: </span>
                  <b>{{ account.apps_reviews }}</b>
                </div>
                <div class="d-block px-1">
                  <a
                    @click="redirectTo(account.id)"
                    class="btn btn-block btn-sm btn-outline-primary text-primary d-flex justify-content-center align-items-center"
                  >
                    Details &nbsp;
                    <feather-icon icon="InfoIcon" size="20" />
                  </a>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import axios from "@axios";
import {
  BRow,
  BCol,
  BCard,
  BCardGroup,
  BCardText,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BForm,
  VBTooltip,
  BButton,
  BContainer,
} from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardGroup,
    BCardText,
    BForm,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    VBTooltip,
    BButton,
    BContainer,
    apexchart: VueApexCharts,
  },
  data() {
    return {
      totalVisits: null,
      clicks: null,
      statisticsItems: [],

      accounts: [],
    };
  },
  mounted() {
    this.getAccountsStatistics();
  },
  created() {
    this.$store.dispatch("appsManager/getAllCampaigns");
    this.$store.dispatch("appsManager/getAllDomains");
    this.$store.dispatch("appsManager/getMainScirpt");
  },
  methods: {
    getAccountsStatistics() {
      axios
        .post("/get-accounts-statistics", {})
        .then((response) => response.data)
        .then((data) => {
          this.accounts = data.accounts;
        })
        .catch(console.error);
    },
    redirectTo(account_id) {
      // localStorage.setItem('account_id', account_id);
      this.$router.push({
        name: "statistics-account",
        params: { account_id },
      });
    },
  },
};
</script>

<style lang="scss"></style>
